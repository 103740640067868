<!--
/**
  * name qlEmpty
  * @desc 公共缺省
  * @author chb
  * @date 2021-09-30
  * @param
  * example
  * <ql-empty :type="content" :global="true"></ql-empty> 默认
  */
 -->
<template>
  <div class="ql-empty">
    <img :src="empty.image" />
    <p>{{ empty.description }}</p>
  </div>
</template>

<script>
const getEmptyImageUrl = (name) => {
  return require('@/assets/images/components/empty/' + name);
};

// 全局缺省
const emptyGlobalTypeList = [
  {
    type: 'content',
    description: '暂无内容',
    image: 'empty-content.png',
  },
  {
    type: 'data',
    description: '暂无数据',
    image: 'empty-data.png',
  },
  {
    type: 'news',
    description: '暂无消息',
    image: 'empty-news.png',
  },
  {
    type: 'network',
    description: '无网络',
    image: 'empty-network.png',
  },
  {
    type: 'web',
    description: '页面出错了',
    image: 'empty-web.png',
  },
  {
    type: 'timetable',
    description: '暂无课表',
    image: 'empty-timetable.png',
  },
  {
    type: 'code',
    description: '二维码已过期',
    image: 'empty-code.png',
  },
  {
    type: 'permission',
    description: '暂无权限',
    image: 'empty-permission.png',
  },
  {
    type: 'announcement',
    description: '暂无公告',
    image: 'empty-announcement.png',
  },
];

// 模块提示
const emptyModuleTypeList = [
  {
    type: 'content',
    description: '暂无内容',
    image: 'empty-md-content.png',
  },
  {
    type: 'data',
    description: '暂无数据',
    image: 'empty-md-data.png',
  },
  {
    type: 'image',
    description: '图片加载中',
    image: 'image-loading.png',
  },
  {
    type: 'imageFail',
    description: '图片加载失败',
    image: 'image-fail.png',
  },
  {
    type: 'task',
    description: '暂无作业',
    image: 'empty-md-task.png',
  },
  {
    type: 'announcement',
    description: '暂无公告',
    image: 'empty-md-announcement.png',
  },
];

export default {
  name: 'ql-empty',
  props: {
    type: {
      type: String,
      default: 'content',
    },
    global: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    typeList() {
      return this.global ? emptyGlobalTypeList : emptyModuleTypeList;
    },
    empty() {
      let typeItem = this.typeList.find((item) => item.type === this.type);
      let { image, description } = typeItem;
      return {
        image: getEmptyImageUrl(image),
        description,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.ql-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 40px 0;
  font-size: 14px;
  color: #808080;

  img {
    margin-bottom: 16px;
  }
}
</style>
