<template>
  <el-dialog
    title="新增要同步帐号的学校"
    :visible.sync="configVisible"
    custom-class="ql-dialog"
    width="700px"
    :close-on-click-modal="false"
    @closed="closeDialog"
  >
    <el-form :model="configForm" ref="configForm" label-width="114px" size="small">
      <el-form-item label="平台：" style="margin-bottom: 4px">
        <div>{{ configForm.projectName || '--' }}</div>
      </el-form-item>
      <el-form-item label="地区：" style="margin-bottom: 18px">
        <div>{{ configForm.platformName || '--' }}</div>
      </el-form-item>
      <el-form-item class="mg-b-24" label="帐号同步方式：" prop="syncType">
        <el-select class="config-form-select" v-model="configForm.syncType" @change="changeSyncType">
          <el-option :value="1" label="在青鹿新增帐号"></el-option>
          <el-option :value="2" label="与青鹿已有帐号匹配"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="mg-b-24" label="选择学校：" prop="syncThirdSchools">
        <div class="relative">
          <el-select
            v-model="configForm.syncThirdSchools"
            multiple
            placeholder="请选择学校"
            size="small"
            class="form-width config-form-select"
            @change="handleChangeSync"
          >
            <div slot="empty" style="padding: 6px 20px" class="mg-b-14">
              <el-input
                ref="searchInput"
                prefix-icon="iconfont icon-icon_sousuo"
                v-model="schoolKeyword"
                class="dropdown_search"
                placeholder="请输入学校名称"
                size="small"
                @keyup.native="debouncedOnSearch"
              />
              <div class="el-select-dropdown__empty">无数据</div>
            </div>
            <div class="pd-l-20 pd-r-20 mg-b-14">
              <el-input
                ref="searchInput2"
                prefix-icon="iconfont icon-icon_sousuo"
                v-model="schoolKeyword"
                class="dropdown_search"
                placeholder="请输入学校名称"
                size="small"
                @keyup.native="debouncedOnSearch"
              />
            </div>
            <el-option
              v-for="item in configSchoolList"
              :key="item.schoolId"
              :label="item.areaName"
              :value="item.schoolId"
              :title="item.areaName"
              style="width: 510px"
            ></el-option>
          </el-select>
          <span
            v-if="configForm.syncThirdSchools.length > 0"
            class="el-input__icon el-input-icon_shanchu"
            @click="handleClearClick"
          >
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-icon_shanchu"></use>
            </svg>
          </span>
        </div>
        <div class="announcement" v-show="configForm.syncType === 2">
          <el-checkbox v-model="configForm.announcement">我确定已准备好待匹配的帐号</el-checkbox>
          <el-button type="text" @click="openAnnouncement">《对接第三方平台-帐号同步操作注意事项》</el-button>
        </div>
      </el-form-item>
    </el-form>
    <p class="text-tip">
      <i class="el-icon-warning"></i>
      确认后将无法再修改以上学校的同步方式，请谨慎操作！
    </p>
    <span slot="footer">
      <el-button @click="closeDialog" size="small">取 消</el-button>
      <el-button type="primary" @click="handleSaveConfig" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
// components
import { Dialog, Form, FormItem, Select, Option, Input, Button, Checkbox } from 'element-ui';
// api
import { getThirdPartConfigSchoolList, searchThirdPartSchoolList, saveThirdPartSync } from '@/api/back-stage';
// debounce
import debounce from 'throttle-debounce/debounce';

export default {
  components: {
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Option.name]: Option,
    [Input.name]: Input,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    propsParams: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      configVisible: false,
      configForm: {
        appKey: '',
        qlAppkey: '',
        projectCode: '',
        projectName: '',
        platformCode: '',
        platformName: '',
        syncType: 1,
        announcement: false,
        syncThirdSchools: [], // 要新增的学校
      },
      schoolKeyword: '', // 学校搜索关键字
      configSchoolList: [], // 可选学校列表
      originSelectedSchoolIds: [], // 当前同步方式下已选的学校id数组
      otherSelectedSchoolIds: [], // 另一种同步方式下已选的学校id数组
      newAddSchoolList: [], // 新增学校列表
    };
  },
  watch: {
    visible: {
      handler(newV) {
        this.configVisible = newV;
        this.configForm = Object.assign(this.configForm, this.propsParams);
        if (newV) {
          this.getSelectedSchoolList();
        }
      },
      immediate: true,
    },
    configSchoolList(val, oldVal) {
      this.$nextTick(() => {
        // 学校搜索框切换时获取焦点（select组件没有头部插槽，options无数据暂时用empty）
        if (val.length > 0 && oldVal.length === 0) {
          this.$refs.searchInput2.focus();
        }
        if (val.length === 0 && oldVal.length > 0) {
          this.$refs.searchInput.focus();
        }
      });
    },
  },
  methods: {
    // 获取已选学校
    getSelectedSchoolList() {
      const config = {
        appKey: this.configForm.appKey,
        projectCode: this.configForm.projectCode,
        syncType: this.configForm.syncType,
      };
      getThirdPartConfigSchoolList(config)
        .then(async (res) => {
          this.originSelectedSchoolIds = (res.result || []).map((item) => item.schoolId);
          this.configSchoolList = res.result || [];

          // 获取另一种同步方式已选的学校
          await this.getOtherSelectedSchoolList();

          // 获取全部学校列表
          this.searchThirdSchool();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取通过另一种同步方式已选的学校
    getOtherSelectedSchoolList() {
      const config = {
        appKey: this.configForm.appKey,
        projectCode: this.configForm.projectCode,
        syncType: this.configForm.syncType === 1 ? 2 : 1,
      };
      return getThirdPartConfigSchoolList(config)
        .then((res) => {
          let data = (res.result || []).map((item) => item.schoolId);
          this.otherSelectedSchoolIds = data || [];
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 搜索学校
    searchThirdSchool() {
      const config = {
        appKey: this.configForm.appKey,
        projectCode: this.configForm.projectCode,
        platformCode: this.configForm.platformCode,
        schoolName: this.schoolKeyword,
      };
      searchThirdPartSchoolList(config)
        .then((res) => {
          const filterPool = [...this.originSelectedSchoolIds, ...this.otherSelectedSchoolIds];
          // 筛选未被同步的学校
          const releaseList = (res.result || []).filter((x) => {
            return !filterPool.includes(x.schoolId);
          });
          this.configSchoolList = releaseList || [];
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 清空已选学校
    handleClearClick() {
      this.configForm.syncThirdSchools = [];
    },
    // 切换帐号同步方式
    changeSyncType() {
      this.getSelectedSchoolList();
    },
    // 选择学校
    handleChangeSync() {
      let mixinList = [...this.newAddSchoolList, ...this.configSchoolList];
      // 去重
      let removeDouble = [];
      mixinList = mixinList.filter((x) => {
        if (!removeDouble.includes(x.schoolId)) {
          removeDouble.push(x.schoolId);
          return true;
        }
      });
      // 筛选留下来的选中学校
      let releaseList = mixinList.filter((x) => this.configForm.syncThirdSchools.includes(x.schoolId));
      this.newAddSchoolList = releaseList;
    },
    // 保存
    handleSaveConfig() {
      if (this.configForm.syncThirdSchools.length === 0) {
        return this.$message.warning('已选学校不能为空');
      }

      if (this.configForm.syncType === 2 && !this.configForm.announcement) {
        return this.$message.warning('请确认是否已准备好待匹配的帐号');
      }

      // 修改后已选择的学校列表
      let selectedThirdSchools = [];
      this.configForm.syncThirdSchools.forEach((id) => {
        let selectedSchool = this.newAddSchoolList.find((s) => s.schoolId === id);
        selectedSchool.status = 1;
        selectedThirdSchools.push(selectedSchool);
      });

      // 根据学校名称计算已选学校对应个数（选择的学校里面若存在2个或2个以上名称相同的，不可以进行保存）
      let obj = {};
      selectedThirdSchools.forEach((s) => {
        // 计算每个学校的个数
        if (obj[s.areaName]) {
          obj[s.areaName] = obj[s.areaName] + 1;
        } else {
          obj[s.areaName] = 1;
        }
      });

      // 校验同区域内是否存在同名
      let repeatArr = Object.keys(obj).filter((key) => obj[key] > 1);
      if (repeatArr.length) {
        let textArray = repeatArr.map((key) => {
          return obj[key] + '个“' + key + '”';
        });
        this.$message({
          type: 'error',
          dangerouslyUseHTMLString: true,
          message: `存在${textArray.join('，')}<br/><br/>请前往AVA后台修改学校名称`,
        });
        return;
      }

      const { appKey, qlAppkey, projectCode, projectName, platformCode, platformName, syncType } = this.configForm;
      const config = {
        appKey,
        qlAppkey,
        projectCode,
        projectName,
        platformCode,
        platformName,
        syncType,
        syncThirdSchools: JSON.stringify(selectedThirdSchools),
      };
      saveThirdPartSync(config)
        .then((res) => {
          if (res.status === 0) {
            this.closeDialog();
            this.$emit('save');
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 关闭弹窗
    closeDialog() {
      this.resetConfigForm();
      this.$emit('close');
    },
    // 新增表单重置
    resetConfigForm() {
      this.$refs.configForm.resetFields();
      this.configForm = {
        appKey: '',
        qlAppkey: '',
        projectCode: '',
        projectName: '',
        platformCode: '',
        platformName: '',
        syncType: 1,
        announcement: false,
        syncThirdSchools: [],
      };
      this.schoolKeyword = '';
      this.configSchoolList = [];
      this.originSelectedSchoolIds = [];
      (this.otherSelectedSchoolIds = []), (this.configVisible = false);
    },
    // 打开注意事项
    openAnnouncement() {
      this.$emit('openAnnouncement');
    },
  },
  created() {
    this.debouncedOnSearch = debounce(600, () => {
      this.searchThirdSchool();
    });
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  padding-right: 8px;
}

.config-form-select {
  width: 100%;
}

.display-none {
  display: none;
}

::v-deep .announcement {
  margin-top: 12px;

  .el-button {
    margin-left: 0;
    padding: 0;
    width: 236px;
  }
}

.text-tip {
  padding-left: 16px;
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 14px;
  color: #ee3e3e;
  background-color: #f8f8f8;

  > i {
    margin-right: 6px;
    font-size: 20px;
    color: #ffa51e;
  }
}
</style>
