<template>
  <div class="third-party-school-account-sync back-stage-main">
    <backStageHeader title="第三方学校帐号同步" subTitle="添加要同步的第三方学校后，帐号方可同步至青鹿">
      <div>
        <el-cascader
          popper-class="platform-area-cascader"
          class="platform-area-cascader mg-r-40"
          v-model="platformArea.value"
          :options="platformArea.list"
          :props="platformArea.props"
          placeholder="选择平台"
          filterable
          clearable
          @change="handleChange"
        ></el-cascader>
        <el-button
          :class="{ 'disable-add-school': disableAdd }"
          slot="reference"
          type="primary"
          size="small"
          icon="iconfont icon-tianjia"
          @click="handleAdd"
          >新增学校</el-button
        >
      </div>
    </backStageHeader>

    <el-table :data="listData.list" class="back-stage-main_table" v-if="listData.list.length" v-loading="loading">
      <el-table-column prop="schoolName" label="学校" min-width="100">
        <template slot-scope="scope">
          <span class="tabel-cell-school-name text-over-ellipsis" :title="scope.row.schoolName">{{
            scope.row.schoolName
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="schoolKey" label="学校编号" min-width="100">
        <template slot-scope="scope">
          <div class="tabel-cell-school-key" v-if="scope.row.schoolKey">
            <span class="school-key-text text-over-ellipsis" :title="scope.row.schoolKey">{{
              scope.row.schoolKey
            }}</span>
            <el-button class="school-key-copy" type="text" size="small" @click="handleCopy(scope.row.schoolKey)"
              >复制</el-button
            >
          </div>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column prop="syncType" label="帐号同步方式" width="255px" min-width="100">
        <template slot-scope="scoped">
          <span>{{ scoped.row.syncType === 1 ? '在青鹿新增' : '与已有帐号匹配	' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="lastSyncTime" label="最近同步时间" width="255px" min-width="100"> </el-table-column>
      <el-table-column label="操作" width="100" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" del @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <qlPagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="listData.pageIndex"
      :page-size="listData.pageSize"
      :total="listData.total"
    ></qlPagination>

    <!-- 空状态 -->
    <div v-if="listData.list.length === 0">
      <qlEmpty type="content" :global="true"></qlEmpty>
      <p class="empty-tips">建议切换平台/地区</p>
    </div>

    <!-- 新增要同步帐号的学校 -->
    <addSchoolDialog
      :visible="configVisible"
      :propsParams="propsParams"
      @openAnnouncement="openAnnouncement"
      @save="saveSchool"
      @close="closeAddSchoolDialog"
    ></addSchoolDialog>

    <!-- 对接第三方平台-帐号同步操作注意事项 -->
    <announcementDialog :visible="announcementVisible" @close="closeAnnouncement"></announcementDialog>
  </div>
</template>

<script>
// components
import { Cascader, Popover, Button, Table, TableColumn } from 'element-ui';
import backStageHeader from '@/components/backStageHeader';
import qlPagination from '@/components/qlPagination';
import qlEmpty from '@/components/qlEmpty/index.vue';
import addSchoolDialog from './addSchoolDialog.vue';
import announcementDialog from './announcementDialog.vue';
// api
import { getThirdProjectList, getThirdPlatformList, getThirdPartSyncList, deleteThirdPartSync } from '@/api/back-stage';
// utils
import { copyText } from '@/utils/common';

export default {
  components: {
    [Cascader.name]: Cascader,
    [Popover.name]: Popover,
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    backStageHeader,
    qlPagination,
    qlEmpty,
    addSchoolDialog,
    announcementDialog,
  },
  data() {
    return {
      // 平台/地区
      platformArea: {
        value: [], // 当前选中的平台/地区
        list: [], // 可选的平台/地区数据
        props: {
          expandTrigger: 'click',
          lazy: true,
          lazyLoad: this.lazyLoadArea,
        },
      },

      // 表格
      loading: false,
      listData: {
        list: [],
        total: 0,
        pageSize: 10,
        pageIndex: 1,
      },

      // 新增要同步的第三方学校
      configVisible: false,
      propsParams: {
        appKey: '',
        projectCode: '',
        projectName: '',
        platformCode: '',
        platformName: '',
      },

      // 注意事项
      announcementVisible: false,
    };
  },
  computed: {
    disableAdd: function () {
      return this.platformArea.value.length === 0;
    },
  },
  watch: {
    'platformArea.value': {
      handler(newV) {
        !newV.length && this.clearPlatformArea();
      },
      deep: true,
    },
  },
  methods: {
    // 加载平台数据
    getPlatform() {
      getThirdProjectList({})
        .then((res) => {
          if (res.result) {
            this.platformArea.list = res.result.map((x) => {
              return {
                value: x.projectCode,
                label: x.projectName,
              };
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 根据平台请求地区数据
    lazyLoadArea(node, resolve) {
      const { level, value } = node;
      //初始化级联选择器时、层级超过2层时，不触发搜索功能
      if (level === 0 || level >= 2) {
        resolve();
      }
      // 只请求一层子节点
      if (level === 1) {
        const config = {
          projectCode: value,
        };
        getThirdPlatformList(config)
          .then((res) => {
            if (res.result) {
              const nodes = res.result.map((x) => {
                return {
                  appKey: x.appKey,
                  qlAppkey: x.qlAppkey,
                  value: x.platformCode,
                  label: x.platformName,
                  leaf: true,
                };
              });
              const index = this.platformArea.list.findIndex((x) => x.value === value);
              this.platformArea.list[index]['children'] = nodes;
              // 通过调用resolve将子节点数据返回，通知组件数据加载完成，必须调用，这是组件要求的
              resolve(nodes);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    // 切换平台/地区
    handleChange() {
      this.listData.pageSize = 10;
      this.getDataList(true);
    },
    // 切换条数
    handleSizeChange(val) {
      this.listData.pageSize = val;
      this.getDataList(true);
    },
    // 切换页码
    handleCurrentChange(val) {
      this.listData.pageIndex = val;
      this.getDataList();
    },
    // 获取当前选中的平台/地区数据
    getPlatformAreaData() {
      let data = {};
      const projectCode = this.platformArea.value[0];
      const project = this.platformArea.list.find((x) => x.value === projectCode);
      if (project) {
        data.projectCode = project.value;
        data.projectName = project.label;
      }
      if (project && project.children && this.platformArea.value.length === 2) {
        const platform = project.children.find((x) => x.value === this.platformArea.value[1]);
        data.appKey = platform.appKey;
        data.qlAppkey = platform.qlAppkey;
        data.platformCode = platform.value;
        data.platformName = platform.label;
      }
      return (data = Object.keys(data).length ? data : false);
    },
    // 清空当前选中的平台/地区数据
    clearPlatformArea() {
      this.listData.list = [];
      this.listData.total = 0;
    },
    // 加载表格数据
    getDataList(reset = false) {
      // 整理接口参数
      let platformAreaData = this.getPlatformAreaData();
      if (!platformAreaData) return;
      const keyArray = Object.keys(platformAreaData);
      if (keyArray.includes('projectName')) {
        delete platformAreaData.projectName;
      }
      if (keyArray.includes('platformName')) {
        delete platformAreaData.appKey;
        delete platformAreaData.qlAppkey;
        delete platformAreaData.projectName;
        delete platformAreaData.platformName;
      }

      this.loading = true;
      reset === true && (this.listData.pageIndex = 1);
      let { pageIndex, pageSize } = this.listData;
      const config = Object.assign(
        {
          pageIndex,
          pageSize,
        },
        platformAreaData
      );

      getThirdPartSyncList(config)
        .then((res) => {
          if (res.result) {
            const { data, recordCount } = res.result;
            this.listData.list = data;
            this.listData.total = recordCount;
          } else {
            this.listData.list = [];
            this.listData.total = 0;
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 复制学校编号
    handleCopy(schoolKey) {
      const copySuccess = copyText(schoolKey);
      copySuccess && this.$message('学校编号复制成功');
    },
    // 删除学校
    handleDelete(row) {
      this.$msgbox
        .confirm('删除后该学校的数据将不会同步至青鹿，是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          deleteThirdPartSync({
            id: row.id,
          }).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
            // 判断是否为最后一页唯一一条数据
            let idx = this.listData.pageIndex;
            if (idx > 1 && this.listData.list && this.listData.list.length === 1) {
              idx--;
            }
            this.handleCurrentChange(idx);
          });
        })
        .catch(() => {});
    },
    // 新增学校
    handleAdd() {
      if (this.disableAdd) {
        this.$message('请先选择平台/地区');
        return;
      }
      let platformAreaData = this.getPlatformAreaData();
      if (!platformAreaData) return;
      this.propsParams = platformAreaData;
      this.configVisible = true;
    },
    // 新增学校成功
    saveSchool() {
      this.getDataList();
    },
    // 关闭新增学校对话框
    closeAddSchoolDialog() {
      this.configVisible = false;
    },
    // 打开注意事项
    openAnnouncement() {
      this.announcementVisible = true;
    },
    // 关闭注意事项
    closeAnnouncement() {
      this.announcementVisible = false;
    },
  },
  mounted() {
    this.getPlatform();
  },
};
</script>

<style lang="scss" scoped src="./index.scss"></style>
<style lang="scss" src="./resetElementUI.scss"></style>
